import { ALLOWED_URL_PATTERNS, UUID_PATTERN } from './allowedUrlPatterns';

type DynamicParams = Record<string, string | number | boolean | undefined>;

export const formatAdditionalAxiosParams = (params: DynamicParams) => {
  if (!params) return '';

  return Object.entries(params)
    .map(([key, _]) => `&${key}=.*`)
    .join('');
};

export function validateUrl(url: string | undefined) {
  if (url === undefined) return false;

  const normalizedUrl = url.replace(/^https?:\/\/[^\/]+/, '');

  const regexPatterns = ALLOWED_URL_PATTERNS.map((pattern) => {
    const regexPathPattern = pattern.replace(/:uuid/g, UUID_PATTERN);
    const [patternPath, patternQuery] = regexPathPattern.split('?');

    const regexPath = `^${patternPath}`;
    const regexQuery = patternQuery
      ? `\\?${patternQuery
          .split('&')
          .map((param, index) => {
            const isOptional = param.startsWith('(') && param.endsWith(')');
            const paramName = isOptional ? param.slice(1, -1) : param;

            const name = paramName.split('=')[0];

            if (isOptional) {
              return `(?:&${name}=([^&]*))?`;
            } else {
              return index === 0 ? `${name}=([^&]*)` : `&${name}=([^&]*)`;
            }
          })
          .join('')}?$`
      : '';

    const regexPattern = `${regexPath}${regexQuery}$`;

    return new RegExp(regexPattern);
  });

  const isValid = regexPatterns.some((regex) => {
    return regex.test(normalizedUrl);
  });

  return isValid;
}
