import useSWR from 'swr';

import { getUserCookies } from 'utils/cookies';

const useUserStore = () => {
  const { userId } = getUserCookies();

  return useSWR<User, ResponseError>(userId ? `/users/${userId}` : null);
};

export default useUserStore;
