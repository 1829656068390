import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useBreadcrumbsReplaceStore } from 'store/common';
import { BCContainer } from 'styles/containers';
import { Text } from 'styles/typography';
import { BREADCRUMB_EXCEPTIONS } from 'utils/constants/breadcrumbExceptions';

import BreadcrumbItem from './BreadcrumbItem';

interface BreadcrumbsProp {
  href: string;
  label: string;
  isCurrent: boolean;
}

const Breadcrumb = () => {
  const router = useRouter();

  const { data: breadcrumbsReplaceData } = useBreadcrumbsReplaceStore();

  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsProp[]>();

  useEffect(() => {
    const allProjectRoutes = (process.env.breadcrumbs as string).split(',') as string[] | undefined;
    if (!allProjectRoutes) return;

    const asPathWithoutQuery = router.asPath.split('?')[0];
    const pathnameWithoutQuery = router.pathname.split('?')[0];

    let asPathArray = asPathWithoutQuery.split('/');
    let pathnameArray = pathnameWithoutQuery.split('/');

    asPathArray.shift();
    pathnameArray.shift();

    asPathArray = asPathArray.filter((path) => path !== '');
    pathnameArray = pathnameArray.filter((path) => path !== '');

    const breadcrumbs: BreadcrumbsProp[] = [];

    asPathArray.forEach((path, index) => {
      const pathname = '/' + pathnameArray.slice(0, index + 1).join('/');

      if (!allProjectRoutes.includes(pathname)) return;

      if (breadcrumbsReplaceData?.[pathnameArray[index]] === null) return;

      const isFirst = breadcrumbs.length <= 0;

      const isException = BREADCRUMB_EXCEPTIONS.includes(path);

      const href = '/' + asPathArray.slice(0, index + 1).join('/');

      const replacedLabel =
        isFirst || isException
          ? breadcrumbsReplaceData?.[pathnameArray[index]]?.split('-').join(' ')
          : breadcrumbsReplaceData?.[pathnameArray[index]];

      const label = isFirst || isException ? path.split('-').join(' ') : path;

      breadcrumbs.push({
        href,
        label:
          isFirst || isException
            ? (replacedLabel ?? label).charAt(0).toUpperCase() + (replacedLabel ?? label).slice(1)
            : replacedLabel ?? label,
        isCurrent: index === asPathArray.length - 1,
      });
    });
    setBreadcrumbs(breadcrumbs);
  }, [breadcrumbsReplaceData, router.asPath, router.pathname]);

  return (
    <BCContainer noWrap aria-label="breadcrumb">
      {breadcrumbs &&
        breadcrumbs.map((breadcrumb, index) => (
          <BCContainer key={breadcrumb.href} noWrap>
            <Text as="div" className="normal" $color="#EBEEF1">
              <BreadcrumbItem href={breadcrumb.href} isCurrent={breadcrumb.isCurrent}>
                {breadcrumb.label}
              </BreadcrumbItem>
            </Text>

            {index !== breadcrumbs.length - 1 ? (
              <Text as="div" className="normal" $color="#EBEEF1">
                {'>'}
              </Text>
            ) : null}
          </BCContainer>
        ))}
    </BCContainer>
  );
};

export default Breadcrumb;
