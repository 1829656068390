import React, { createContext, useContext } from 'react';
import useSWR from 'swr';
import axios from 'axios';

interface VersionProviderProps {
  children: any;
}

interface VersionContextType {
  version?: string;
  error?: any;
}

// Define the context with an initial state indicating that data may be undefined initially
const VersionContext = createContext<VersionContextType>({
  version: undefined,
  error: undefined,
});

// Fetcher function for useSWR
const fetchVersionData = async () => {
  const response = await axios.get(`${window.location.origin}/version`);

  return response.data;
};

export const VersionProvider: React.FC<VersionProviderProps> = ({ children }) => {
  const { data, error } = useSWR<string, ResponseError>('version', fetchVersionData);

  return (
    <VersionContext.Provider value={{ version: data, error }}>{children}</VersionContext.Provider>
  );
};

// Custom hook for consuming the context
export const useVersion = () => {
  const context = useContext(VersionContext);
  if (context === undefined) {
    throw new Error('useVersion must be used within a VersionProvider');
  }

  return context;
};
