import { breadcrumbsReplaceDefault } from 'utils/constants/breadcrumbsReplaceDefault';

import useSWRStore from '../useSWRStore';

const useBreadcrumbsReplaceStore = () =>
  useSWRStore<{ [k: string]: string | null }, unknown>('breadcrumbs-replace-store', {
    fallbackData: breadcrumbsReplaceDefault,
  });

export default useBreadcrumbsReplaceStore;
