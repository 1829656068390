import { useEffect } from 'react';

interface Props {
  action: () => void;
  codes: string[];
}

const useCombineKeysListener = ({ action, codes }: Props) => {
  useEffect(() => {
    const pressed = new Set();

    const handleKeyDown = (event: KeyboardEvent) => {
      pressed.add(event.code);

      for (const code of codes) {
        if (!pressed.has(code)) {
          return;
        }
      }

      pressed.clear();
      action();
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      pressed.delete(event.code);
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [action, codes]);
};

export default useCombineKeysListener;
