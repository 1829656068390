import useSWR from 'swr';

import { getUserCookies } from 'utils/cookies';
import FALLBACK_DEFAULTS from 'utils/constants/fallbackDefaults';

const useDefaultsStore = () => {
  const { userId } = getUserCookies();

  return useSWR<DefaultSettings, ResponseError>(userId ? '/defaults' : null, {
    dedupingInterval: 9999999,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenHidden: false,
    fallbackData: FALLBACK_DEFAULTS,
  });
};

export default useDefaultsStore;
