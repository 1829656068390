import useSWRStore from '../useSWRStore';

// const useEditorColumnNamesWithDashStore = () =>
//   useSWRStore<string[], unknown>('monaco-editor-detected-table-columns', {
//     revalidateIfStale: false,
//     revalidateOnFocus: false,
//     revalidateOnReconnect: false,
//     refreshWhenHidden: false,
//     fallbackData: [],
//   });

const useEditorColumnNamesWithDashStore = () =>
  useSWRStore<string[], unknown>('monaco-editor-detected-table-columns', { fallbackData: [] });

export default useEditorColumnNamesWithDashStore;
