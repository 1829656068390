import { FC, useCallback, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import ReactTooltip from 'react-tooltip';

import { getUserCookies } from 'utils/cookies';
import { Header } from 'views/features/Header';
import { Sidebar } from 'views/features/Sidebar';
import { MainContainer } from 'views/components/Container/MainContainer';
import SKIP_PROTECTED_ROUTES from 'utils/constants/skipProtectedRoutes';
import * as UI from 'styles/main-layout';
import { useToast } from 'views/components/Toast';
import Loading from 'views/features/Loading';
import { useUserStore } from 'store/user';
import { useResourceStore } from 'store/resource';
import { useDefaultsStore } from 'store/defaults';
import { useCombineKeysListener } from 'src/hooks/listener';
import { ROUTES } from 'utils/constants/routes';

export interface IMainLayout {
  children: React.ReactNode;
}

const MainLayout: FC<IMainLayout> = ({ children }) => {
  const router = useRouter();

  const { renderToasts: RenderToasts } = useToast(2000);

  const { userId, isExpired } = getUserCookies();

  const { data, error } = useUserStore();

  // add summary to store if orgId present
  useResourceStore();
  useDefaultsStore();

  const handleRoute = useCallback(() => {
    router.push(ROUTES.alterNew);
  }, [router]);

  const codes = useMemo(() => ['AltLeft', 'KeyN', 'KeyA'], []);

  useCombineKeysListener({
    action: handleRoute,
    codes,
  });

  useEffect(() => {
    if (
      (isExpired && !SKIP_PROTECTED_ROUTES.includes(router.pathname)) ||
      (error && error?.code === 401)
    ) {
      router.push(`/login?prevUrl=${window.location.pathname}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpired, router]);

  if ((data === undefined && error === undefined) || !userId) return <Loading />;

  return (
    <UI.MainLayout>
      <ReactTooltip effect="solid" delayHide={50} role="tooltip" />
      <Header />

      <UI.Container>
        <Sidebar />
        <MainContainer>{children}</MainContainer>
      </UI.Container>
      <RenderToasts />
    </UI.MainLayout>
  );
};

export default MainLayout;
