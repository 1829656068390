import useSWRStore from '../useSWRStore';

interface Options {
  key: string | null;
}

const useBooleanStore = ({ key }: Options) =>
  useSWRStore<boolean, unknown>(key, { fallbackData: false });

export default useBooleanStore;
