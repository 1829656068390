import useSWR from 'swr';

import { getUserCookies } from 'utils/cookies';

const useResourceStore = <K>(options?: K) => {
  const { orgId } = getUserCookies();

  return useSWR<OrgSummary, ResponseError>(orgId ? `/orgs/${orgId}/summary?meta=1` : null, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenHidden: false,
    ...(options ?? {}),
  });
};

export default useResourceStore;
