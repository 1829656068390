import { FC, ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

import * as UI from 'styles/main-container';
import { useBreadcrumbsReplaceStore, useScrollToStore } from 'store/common';
import { breadcrumbsReplaceDefault } from 'utils/constants/breadcrumbsReplaceDefault';

const ScrollToTop = dynamic(() => import('views/components/ScrollToTop/ScrollToTop'), {
  ssr: false,
});

interface IMainContainer {
  children: ReactNode;
}

const MainContainer: FC<IMainContainer> = ({ children }) => {
  const router = useRouter();
  const { data: scrollData, mutate } = useScrollToStore();
  const { mutate: breadcrumbsReplaceMutate } = useBreadcrumbsReplaceStore();

  useEffect(() => {
    const handleRouteChange = () => {
      mutate(null, false);
      breadcrumbsReplaceMutate(breadcrumbsReplaceDefault, false);
    };

    router.events.on('beforeHistoryChange', handleRouteChange);

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange);
    };
  }, [breadcrumbsReplaceMutate, mutate, router.events, scrollData]);

  return (
    <>
      {scrollData && <ScrollToTop />}
      <UI.MainContainer id="main-render-container">{children}</UI.MainContainer>
    </>
  );
};

export default MainContainer;
