import { FC, ReactNode } from 'react';
import Link from 'next/link';

import { BCContainer } from 'styles/containers';
import { Text } from 'styles/typography';

interface BreadcrumbItemProps {
  children: ReactNode;
  href: string;
  isCurrent: boolean;
}

const BreadcrumbItem: FC<BreadcrumbItemProps> = ({ children, href, isCurrent, ...props }) => {
  return (
    <BCContainer {...props}>
      {isCurrent ? (
        <Text
          className="normal nowrap with-pointer"
          $color="#EBEEF1"
          aria-current={isCurrent ? 'page' : 'false'}
        >
          {children}
        </Text>
      ) : (
        <Link href={href} passHref>
          <Text
            className="normal nowrap"
            $color="#EBEEF1"
            aria-current={isCurrent ? 'page' : 'false'}
          >
            {children}
          </Text>
        </Link>
      )}
    </BCContainer>
  );
};

export default BreadcrumbItem;
