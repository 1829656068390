import styled from 'styled-components';

const InfoWrapper = styled.div<{
  $isFullSize?: boolean;
  $isOverflow?: boolean;
  marginTop?: number;
}>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: ${({ marginTop }) => marginTop ?? 10}px;
  margin-bottom: -10px;
  width: 100%;

  ${({ $isFullSize }) => $isFullSize && `width: calc(100vw - 320px)`};
  ${({ $isOverflow }) => $isOverflow && `overflow: auto`};
`;

const InfoContainer = styled.div<{ margin?: string }>`
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 10px;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
  padding: 10px 20px;
  font-size: ${(p) => p.theme.typo.p.size.md};
  line-height: ${(p) => p.theme.typo.p.lineHeight.md};
  color: ${(p) => p.theme.color.danger[10]};
  ${({ margin }) => margin && `margin: ${margin}`};

  &.withoutIcon {
    display: flex;
  }

  &.error {
    background-color: ${(p) => p.theme.color.danger[0]};
  }

  &.info {
    background-color: ${(p) => p.theme.color.gray[1]};
  }

  &.warning {
    background-color: ${(p) => p.theme.color.gray[1]};
  }
`;

const InlineTextWrapper = styled.span<{ $isOverflow?: boolean }>`
  ${({ $isOverflow }) => $isOverflow && 'overflow: auto'};
`;

export { InfoContainer, InfoWrapper, InlineTextWrapper };
