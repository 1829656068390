import { useEffect } from 'react';

import useSWRStore from '../useSWRStore';

const useScrollToStore = () => {
  const api = useSWRStore<boolean, unknown>('scroll-to-top-store', { fallbackData: false });

  useEffect(() => {
    api.mutate(true, false);
  }, [api]);

  return api;
};

export default useScrollToStore;
