export const ACCESS_TOKEN = 'HYDROLIX_TOKEN';

export const USER_ID = 'HYDROLIX_USER_ID';

export const TOKEN_EXPIRES_IN = 'HYDROLIX_TOKEN_EXPIRES_IN';

export const EXPIRY_DATE = 'HYDROLIX_EXPIRY_DATE';

export const ORGANIZATION_ID = 'HYDROLIX_ORGANIZATION_ID';

export const TIMEZONE = 'TIMEZONE';
